<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="searchView">
			<el-input v-model="keyword" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
				style="width: 200px;margin-right: 8px;" @keyup.enter.native="handleSearchClick"></el-input>
			<el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick">查询</el-button>
			<div class="search">
				<div class="searchTitle">盘点日期:</div>
				<el-date-picker v-model="inventoryDate" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" style="width: 260px;" @change="handleSearchClick">
				</el-date-picker>
			</div>
			<div class="btnView">
				<el-button v-if="$buttonAuthority('cangku-pandianList-export')" @click='exportClick'>导出</el-button>
				<el-button @click="handleResetClick">重置</el-button>
				<el-button v-if="$buttonAuthority('cangku-pandianList-addpandian')" type="primary" @click="handleAddClick">新建盘点</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="view">
			<div class="tableView">
				<el-table :data="tableData">
					<el-table-column prop="inventoryOrderNo" label="盘点单号">
					</el-table-column>
					<el-table-column prop="inventoryDate" label="盘点日期">
					</el-table-column>
					<el-table-column prop="inventoryUserName" label="盘点人">
					</el-table-column>
					<el-table-column prop="inventoryBeforeCount" label="盘前数量">
					</el-table-column>
					<el-table-column prop="inventoryAfterCount" label="盘后数量">
					</el-table-column>
					<el-table-column label="亏盈数量">
						<template slot-scope="scope">
							{{scope.row.inventoryAfterCount - scope.row.inventoryBeforeCount}}
						</template>
					</el-table-column>
					<el-table-column prop="remark" label="盘点备注">
					</el-table-column>
					<el-table-column label="操作" width="100" v-if="$buttonAuthority('cangku-pandianList-seepandian')">
						<template slot-scope="scope">
							<el-button @click="handleSeeClick(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div v-if="dialogStockDetail_state">
			<dialogStockDetail @submit="submit_dialogStockDetail" :stockId="stockId" :stockType="stockType"></dialogStockDetail>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import page from "../../../components/page.vue"
	import dialogStockDetail from "../../../components/dialogStockDetail.vue"
	export default {
		components: {
			page,
			dialogStockDetail
		},
		data() {
			return {
				page: { //分页
					pageIndex: 1,
					pageSize: 10,
					total: 0,
				},
				pageSizes: [10, 15,30,60],
				tableData: [], //表格
				keyword:'',  //搜索
				inventoryDate:[], //盘点日期
				dialogStockDetail_state:false,
				stockId:'',  //跳转对象
				stockType:2,  //类型
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//导出
			exportClick(){
				axios({
						method: 'get',
						url: process.env.VUE_APP_export+`/store/inventory/list/export`,
						responseType: 'blob',
						params:{
							keyword: this.keyword,
							startDate: new Date(this.inventoryDate[0]).getTime() / 1000||'',
							endDate: new Date(this.inventoryDate[1]).getTime() / 1000||'',
						}
					})
					.then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data], {
							type: "application/ms-excel"
						}));
						const link = document.createElement('a');
						link.style.display = 'none';
						link.href = url;
						link.setAttribute('download', `盘点导出.xls`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						window.URL.revokeObjectURL(url);
					})
			},
			//筛选
			handleSearchClick(){
				if(this.inventoryDate == null){
					this.inventoryDate = [];
				}
				this.page.pageIndex = 1;
				this.getTableData()
			},
			//关闭详情
			submit_dialogStockDetail(){
				this.dialogStockDetail_state = false;
			},
			//查看
			handleSeeClick(row){
				this.stockId = row.id;
				this.dialogStockDetail_state = true;
			},
			//新建
			handleAddClick(){
				this.$router.push({path:'pandianAdd',query:{}})
			},
			//切换分页
			changePage(num) {
				this.page.pageIndex = num
				this.getTableData();
			},
				handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			//表格
			getTableData() {
				let params = {
					pageIndex: this.page.pageIndex,
					pageSize: this.page.pageSize,
					keyword: this.keyword,
					startDate: new Date(this.inventoryDate[0]).getTime() / 1000,
					endDate: new Date(this.inventoryDate[1]).getTime() / 1000,
				}
				this.$http.post("/store/inventory/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			},
			//重置
			handleResetClick() {
				this.keyword = '';
				this.inventoryDate = [];
				this.page.pageIndex = 1;
				this.getTableData()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
		display: flex;
		flex-direction: column;

		.searchView {
			height: 80px;
			border-radius: 20px;
			margin-bottom: 16px;
			background: #fff;
			display: flex;
			align-items: center;
			padding: 0 20px;

			.search {
				display: flex;
				align-items: center;
				margin-right: 32px;

				.searchTitle {
					font-size: 14px;
					color: $fontColor;
					margin-right: 4px;
				}
			}

			.btnView {
				flex: 1;
				text-align: right;
			}
		}

		.view {
			border-radius: 20px;
			overflow: hidden;
			flex: 1;
			background: #fff;
			display: flex;
			flex-direction: column;
		
			.tableView {
				flex: 1;
				overflow-y: scroll;
			}
			.tableView::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
